import getData from "@/__main__/get-data.mjs";
import ValorantLatestPlayerStats from "@/data-models/valorant-latest-player-stats.mjs";
import ValorantPlayerMapStats from "@/data-models/valorant-player-map-stats.mjs";
import ValorantPlayerMatchWinHistoryModel from "@/data-models/valorant-player-match-win-history.mjs";
import ValorantPlayerStats from "@/data-models/valorant-player-stats.mjs";
import * as API from "@/game-val/api.mjs";
import fetchConstants from "@/game-val/fetch-constants-data.mjs";
import fetchLiveMatch from "@/game-val/fetch-live-match.mjs";
import { separateNameTag } from "@/game-val/utils.mjs";
import { getPlatformPath } from "@/game-val/utils/console.mjs";
import fetchProfile, {
  getAuthorizationHeaders,
} from "@/game-val/valorant-fetch-profile.mjs";
import { devWarn } from "@/util/dev.mjs";

const fetchLivePageData = async ([nameTag]) => {
  const { name, tag } = separateNameTag(nameTag);
  const player = await fetchProfile(name, tag);
  if (!player || !player.valorantProfile?.region) {
    devWarn("Mandatory info is missing from profile data", player);
    return;
  }

  const headers = await getAuthorizationHeaders();
  const [content] = await fetchConstants();

  const puuid = player.puuid;
  const region = player.valorantProfile.region;
  const latestActId = content.acts?.find((act) => act.endedAt === null)?.uuid;
  const liveMatch = await fetchLiveMatch(player);
  const platformPath = getPlatformPath(false); // PC only for live
  if (!liveMatch) return null;
  const promises = [];

  const queue = liveMatch.queue;
  const getPlayerMapStats = getData(
    API.getMapStats({
      puuid,
      actUuid: latestActId,
      region,
      queue,
    }),
    ValorantPlayerMapStats,
    ["val", "playerMapStats", puuid, latestActId, platformPath, queue],
    { headers },
  );
  promises.push(getPlayerMapStats);

  const getLatestPlayerStats = getData(
    API.getLatestPlayerStats({
      puuid,
      region,
      queue,
    }),
    ValorantLatestPlayerStats,
    ["val", "latestPlayerStats", puuid, queue],
    { headers },
  );
  promises.push(getLatestPlayerStats);

  const getPlayerStats = getData(
    API.getPlayerStats({
      puuid,
      actUuid: latestActId,
      region,
    }),
    ValorantPlayerStats,
    ["val", "playerActStats", puuid, latestActId, platformPath],
    { headers },
  );
  promises.push(getPlayerStats);

  const playerProfiles =
    liveMatch.players?.map(({ gameName, tagLine }) => {
      return fetchProfile(gameName, tagLine).then((profile) => {
        if (!profile || !profile.valorantProfile || profile instanceof Error)
          return;
        return Promise.all([
          getData(
            API.getPlayerStats({
              puuid: profile.puuid,
              actUuid: latestActId,
              region: profile.valorantProfile.region,
            }),
            ValorantPlayerStats,
            ["val", "playerActStats", profile.puuid, latestActId, platformPath],
            { headers },
          ),
          getData(
            API.getMatchWinHistory({
              puuid: profile.puuid,
              actUuid: latestActId,
              queue,
              offset: 0,
              first: 5,
            }),
            ValorantPlayerMatchWinHistoryModel,
            ["val", "playerMatchWinHistory", profile.puuid, latestActId, queue],
            { headers },
          ),
        ]);
      });
    }) ?? [];

  promises.push(...playerProfiles);

  return Promise.all(promises);
};

export default fetchLivePageData;
